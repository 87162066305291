import React from "react";
import { isProductionDomain } from "../utils/helperFunctions";

export const useFbPixel = () => {
  React.useEffect(() => {
    if (isProductionDomain()) {
      const script = document.createElement('script');
      script.setAttribute("type", "text/javascript");
      // script.setAttribute("SameSite", "Secure");
      //    <noscript>
      // <img height="1" width="1" 
      // src="https://www.facebook.com/tr?id=1394084881428060&ev=PageView
      // &noscript=1"/>
      // </noscript>
      let code = `
      !function(f,b,e,v,n,t,s)
      { 
        if(f.fbq)return;
        n=f.fbq = function(){
          n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)
      };
        if(!f._fbq)
        f._fbq=n;
        n.push=n;
        n.loaded=!0;
        n.version='2.0';
        n.queue=[];
        t=b.createElement(e);
        t.async=!0;
        t.src=v;
        s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)
      }
      (window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '621912139288838'); 
        fbq('track', 'PageView');
  `
  
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }
  }, []);
};

export const useFbPixelNoScript = () => {
  React.useEffect(() => {
    if (isProductionDomain()) {
      const script = document.createElement('noscript');
      // script.setAttribute("type", "text/javascript");
      let noscript = '<img height="1" width="1" src="https://www.facebook.com/tr?id=621912139288838&ev=PageView&noscript=1"/>'
  
      script.innerHTML += noscript;
      var d = document;
      let s = d.getElementsByTagName("body")[0];
      if (s.parentNode) {
        s.parentNode.insertBefore(script, s);
        // script.appendChild(document.createTextNode(code));
        document.body.appendChild(script);
      }
  
      return () => {
        document.body.removeChild(script);
      }
    }
  }, []);
};