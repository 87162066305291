import { useEffect } from 'react';
import { isProductionDomain } from '../utils/helperFunctions';

export const useLuckyorange = () => {
  useEffect(() => {
    if (isProductionDomain()) {
      const script = document.createElement('script');
      script.src = "https://tools.luckyorange.com/core/lo.js?site-id=1f61162c";
      script.async = true;
      script.defer = true;
      
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);
};