import React from "react";
import { isProductionDomain } from "../utils/helperFunctions";

export const useScriptZoho = (url:any, widgetCode: any) => {
  React.useEffect(() => {
    if (isProductionDomain()) {
      const script = document.createElement('script');
      script.setAttribute("type", "text/javascript");
  
      let code = `
        var $zoho=$zoho || {};
        $zoho.salesiq = $zoho.salesiq || {
          widgetcode: "${widgetCode}", values:{},ready:function(){}
        };
        var d=document;
        s=d.createElement("script");
        s.type="text/javascript";
        s.id="zsiqscript";
        s.defer=true;
        s.src="${url}";
        
        t=d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(s,t);
        d.innerHTML = "<div id='zsiqwidget' ></div>";
      `;
      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }
  }, []);
};

export const usePageSenseScript = () => {
  React.useEffect(() => {
    // if (isProductionDomain()) {
      const script = document.createElement('script');
      script.src = "https://cdn.pagesense.io/js/grupoaries/f0cb9d9659fc4057802f3385a0da3c55.js";
      
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    // }
  }, []);
}